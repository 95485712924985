
























































































import Footer from "@monorepo/uikit/src/components/tableViews/Footer.vue";
import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";

enum STEPS {
  FIRST_STEP = "FIRST_STEP",
  SECOND_STEP = "SECOND_STEP",
}

export default defineComponent({
  name: "LoginView",
  components: {
    Footer,
  },
  data() {
    return {
      STEPS,
      step: STEPS.FIRST_STEP,
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      isShowPassword: false,
      newPasswordAgain: "",
    };
  },
  computed: {
    title(): string {
      switch (this.step) {
        case STEPS.FIRST_STEP:
          return "Проверка старого пароля";
        case STEPS.SECOND_STEP:
          return "Создание нового пароля";
        default:
          return "";
      }
    },
    btnTitle(): string {
      switch (this.step) {
        case STEPS.FIRST_STEP:
          return "Проверить пароль";
        case STEPS.SECOND_STEP:
          return "Установить пароль";
        default:
          return "";
      }
    },
    imgPath(): string {
      return `${environmentVariables.BASE_URL}assets/images/navigation/logo-blue.svg`;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("app", ["checkOldPassword", "saveNewPassword"]),
    goToArchiveBaseUrl() {
      window.open("https://archives.gov.ru/", "_blank");
    },
    goToBaseUrl() {
      window.open("https://digital.gov.ru/", "_blank");
    },
    async onSubmit(e: Event) {
      e.preventDefault();

      this.isLoading = true;

      try {
        if (this.step === STEPS.SECOND_STEP) {
          try {
            if (this.newPassword !== this.newPasswordAgain) {
              showNotification("Ошибка: пароли не совпадают");
              return;
            }

            if (this.newPassword === this.oldPassword) {
              showNotification("Ошибка: пароль уже использовался ранее");
              return;
            }

            const idDone = await this.saveNewPassword({ password: this.newPassword, hash: this.$route.params.hash });

            if (idDone) {
              await this.logout();
              await this.$router.push("/login");
            }
          } catch (e) {
            showNotification("Ошибка сброса пароля.");
          }
        }

        if (this.step === STEPS.FIRST_STEP) {
          try {
            const idDone = await this.checkOldPassword(this.oldPassword);

            if (!idDone) {
              showNotification("Ошибка: пароль неверный");
            } else {
              this.step = STEPS.SECOND_STEP;
            }
          } catch (e) {
            showNotification("Ошибка сброса пароля.");
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (!this.$store?.state?.auth?.user?.details?.refreshToken && !this.$route.params.hash) {
      this.$router.push({ path: "/login" });
      return;
    }
    if (this.$route.params.hash) {
      this.step = STEPS.SECOND_STEP;
    }
  },
});
